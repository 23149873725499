import { useMemo } from "react";

import { SystemTheme } from "@wind/db/kysely/types";
import { useCurrentPath } from "@wind/ui";

import AuthPathUtil from "../auth/AuthPathUtil.js";
import { useOptionalUser } from "../hooks/useUser.js";
import getThemeVariables from "./getThemeVariables.js";
import { ADMIN_THEME } from "./themes.js";

const useUserTheme = () => {
  const user = useOptionalUser();

  return user?.theme ?? SystemTheme.LIGHT;
};

const useCurrentThemeVariables = () => {
  const theme = useUserTheme();
  const currentPath = useCurrentPath();

  return useMemo(() => {
    if (AuthPathUtil.isAdminPath(currentPath)) {
      return ADMIN_THEME;
    } else if (AuthPathUtil.isAuthedPath(currentPath)) {
      return getThemeVariables(theme);
    } else {
      return getThemeVariables(SystemTheme.LIGHT);
    }
  }, [theme, currentPath]);
};

const useDefaultThemeVariables = () => {
  return getThemeVariables(SystemTheme.LIGHT);
};

export { useUserTheme, useCurrentThemeVariables, useDefaultThemeVariables };
